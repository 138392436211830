import React from "react";
import { Widget } from "@typeform/embed-react";

import "./typeform.scss";

const Typeform = () => {
  return (
    <Widget className="typeform" id="Gj9p4AUE" height="100%" opacity={100} />
  );
};

export default Typeform;
