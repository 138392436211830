import { Routes, BrowserRouter, Route } from "react-router-dom";

import Layout from "components/Layout";
import Home from "pages/Home";
import Typeform from "pages/Typeform";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route path="/apply" element={<Typeform />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
