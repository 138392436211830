import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./layout.scss";
import { CloseIcon, HamburgerIcon } from "assets/images";
import { LINKS } from "./config";
import { useDisableBodyScroll } from "hooks";

const Layout = ({ children }) => {
  const [border, setBorder] = useState(false);
  const [menu, toggleMenu] = useState(false);
  useDisableBodyScroll(menu);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const listenScrollEvent = () => {
    if (window.scrollY < 10) {
      setBorder(false);
    } else {
      setBorder(true);
    }
  };

  return (
    <div className="layout">
      <div className={`overlay ${menu && "overlay--visible"}`}>
        <header className="header">
          <div className="header__content">
            <div className="header__logo">Workease</div>
            <CloseIcon
              onClick={() => toggleMenu(false)}
              className="header__hamburger"
            />
          </div>
        </header>
        <div className="overlay__list">
          {LINKS.map(({ title, path }) => (
            <Link
              onClick={() => toggleMenu(false)}
              key={title}
              to={path}
              className="overlay__list__item"
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
      <div className={`header ${border ? "header--border" : ""}`}>
        <div className="header__content">
          <div className="header__hamburger" />

          <Link to="/" className="header__link">
            About Us
          </Link>
          <div className="header__logo">Workease</div>
          <Link to="/apply" className="header__link">
            Subscriptions
          </Link>

          <HamburgerIcon
            onClick={() => toggleMenu(true)}
            className="header__hamburger"
          />
        </div>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default Layout;
