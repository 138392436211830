const LINKS = [
  {
    title: "About Us",
    path: "/",
  },
  {
    title: "Subscriptions",
    path: "/",
  },
];

export { LINKS };
