import React from "react";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

import "./carousel.scss";
import { NextIcon, PolygonIcon, PrevIcon } from "assets/images";
import { data } from "./config";

const Carousel = () => {
  return (
    <div className="carousel">
      <ReactCarousel
        swipeable={false}
        showIndicators={false}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className={`${
              !hasPrev ? "carousel__btn--disabled" : ""
            } carousel__btn carousel__btn--prev`}
          >
            <PrevIcon fill={!hasPrev ? "#BCAEF0" : "#fff"} />
          </button>
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => (
          <button
            className={`${
              !hasNext ? "carousel__btn--disabled" : ""
            } carousel__btn carousel__btn--next`}
            type="button"
            onClick={onClickHandler}
            title={label}
          >
            <NextIcon fill={!hasNext ? "#BCAEF0" : "#fff"} />
          </button>
        )}
      >
        {data.map(({ id, img, benefits, title }) => (
          <div key={id} className="carousel__item">
            <div className="carousel__item__image">
              <img alt="service" src={img} />
            </div>
            <div className="carousel__item__content">
              <div className="carousel__item__content__title">{title}</div>
              <div className="carousel__item__content__benefits">
                {benefits?.map((benefit, i) => (
                  <div key={i} className="carousel__item__content__benefit">
                    <div className="carousel__item__content__benefit__svg">
                      <PolygonIcon />
                    </div>
                    <span>{benefit}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </ReactCarousel>
    </div>
  );
};

export default Carousel;
