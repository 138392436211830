const {
  Service1,
  Service2,
  Service5,
  Service4,
  Service3,
} = require("assets/images");

const data = [
  {
    id: 1,
    img: Service1,
    title: "Insurance",
    benefits: [
      "We cover self, spouse, children and parents.",
      "Choose the OPD/IPD medical insurance package that best suits your needs.",
      "You decide.",
    ],
  },
  {
    id: 2,
    img: Service2,
    title: "Tech Gadgets",
    benefits: [
      "Quality work demands quality gear.",
      "Get the gear you have always wanted at discounted rates.",
      "Every day is black friday at workease.",
    ],
  },
  {
    id: 3,
    img: Service3,
    title: "Workgears & Internet",
    benefits: [
      "We understand high speed internet is what enables you to produce world-class work.",
      "We offer discounted internet packages with all major internet providers.",
      "Work smart & super fast.",
    ],
  },
  {
    id: 4,
    img: Service4,
    title: "Car Insurance",
    benefits: [
      "Your car is your income, let us get your asset insured and arrange periodic service and parts at the best rates.",
    ],
  },
  {
    id: 5,
    img: Service5,
    title: "Tax Returns",
    benefits: [
      "Filing taxes can be a pain, we file your taxes so you can focus on the work that matters.",
    ],
  },
];

export { data };
