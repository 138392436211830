import { useEffect, useState } from "react";
import Typed from "react-typed";
import { useNavigate } from "react-router";

import "./home.scss";
import { CoverImage, NextIcon } from "assets/images";
import Carousel from "components/Carousel";
import { validateEmail } from "utils";

const Home = () => {
  const [emailVisible, setEmailVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  let navigate = useNavigate();
  const [mQuery, setMQuery] = useState(
    window.innerWidth <= 1024 ? true : false
  );
  const [email, setEmail] = useState("");

  useEffect(() => {
    let mediaQuery = window.matchMedia("(max-width: 1024px)");
    mediaQuery.addEventListener("change", handleMQuery);
    return () => mediaQuery.removeEventListener("change", handleMQuery);
  }, []);

  const handleMQuery = () =>
    setMQuery(window.innerWidth <= 1024 ? true : false);

  const apply = () => navigate("/apply");

  return (
    <div className="home">
      <div className="home__cover">
        <div className="home__cover__title">
          Corporate Benefits for {!mQuery && <br />}
          <Typed
            strings={[
              "freelancers",
              "independent contract",
              "Uber driver",
              "home chef",
              "small business owner",
            ]}
            typeSpeed={100}
            backSpeed={100}
            backDelay={800}
            startDelay={800}
            loop
          />
          .
        </div>
        <div className="home__cover__sub-title">
          You decide your hours and salary, now decide your{" "}
          <span className="home__cover__sub-title--highlighted">benefits</span>
        </div>
        <div className="home__cover__btn">
          <button onClick={apply}>Build your package</button>
        </div>
        <div className="home__cover__img">
          <img src={CoverImage} alt="cover" />
        </div>
      </div>
      <div className="home__services">
        <div className="home__services__head-note">Why Workease?</div>
        <div className="home__services__title">
          We help you build the perfect package <br />
          for your life as a <span>freelancer.</span>
        </div>
        <Carousel />
      </div>

      <div className="home__subscriptions">
        <div className="home__subscriptions__info">
          <div className="home__subscriptions__title">
            Subscription{" "}
            {!mQuery && (
              <>
                <br />
                that suits you
              </>
            )}
          </div>
          <div className="home__subscriptions__desc">
            We offer flexible monthly & yearly subscription for your benefits
            package
          </div>
        </div>
        <div className="home__subscriptions__cards">
          <div className="home__subscriptions__card">Monthly</div>
          <div className="home__subscriptions__card">Yearly</div>
        </div>
      </div>
      <div
        className={` ${
          emailVisible ? "home__workspace__emailVisible" : ""
        } home__workspace`}
      >
        <div className="home__workspace__header">
          <div className={`home__workspace__title`}>
            {emailSent ? <span>You're in!</span> : "Don’t just work, workease."}
          </div>
        </div>
        {emailSent ? null : (
          <div className="home__workspace__footer">
            <div className="home__workspace__input">
              <input
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </div>
            <button
              onClick={() => {
                if (!emailVisible && !mQuery) {
                  setEmailVisible(true);
                } else {
                  if (email.length && validateEmail(email)) {
                    setEmailSent(true);
                  }
                }
              }}
              type="button"
              className="home__workspace__btn"
            >
              {emailVisible && !mQuery ? (
                <span>Done</span>
              ) : (
                <NextIcon fill="#573cbc" />
              )}
            </button>
          </div>
        )}
      </div>
      <div className="home__email">
        <div className="home__email__title">Workease</div>
        <div className="home__email__field">
          <input placeholder="Enter your email" type="email" />
          <button type="button">Get Started</button>
        </div>
        <div className="home__email__links">
          <span>About</span>
          <span>Subscriptions</span>
        </div>
      </div>
      <div className="home__footer">
        <div className="home__footer__rights">
          © 2021 Workease. All rights reserved
        </div>
        <div className="home__footer__links">
          <span>Terms & Condition</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
